<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="Noitem=Noitem.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" @keyup.enter="UserDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="UserDataSelect(Noitem)"></el-button>
            </div>
            <label>{{$t('All.输入IPQC模版料号')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: ''
    }
  },
  created () {
    this.User = this.$store.state.Login
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'Productname', 'IPQCadminnumberfun']),
    async UserDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Ipqc/Ipqcdataselet', postdata)
      if (res.status !== 200) {
        loadingInstance.close()
        this.UserData(null)
        this.Productname(row)
        this.QcCount()
        return
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.成功'))
      this.UserData(res.response)
      this.$store.state.TabComponent = 'IPQCAdmin'
      this.Addtab(this.$t('All.IPQC模版') + '-' + res.response.Number, 'IPQCAdmin')
      this.RemoveTab(this.$t('All.新增IPQC模版'))
    },
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'IPQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.IPQCadminnumberfun(res.response)
        this.$message.success(this.$t('All.数据不存在'))
        this.Addtab(this.$t('All.IPQC模版') + '-' + res.response, 'IPQCAdmin')
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
